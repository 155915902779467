<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'EmployeePolicy', 'EmployeeCondition')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>
		<b-form @submit.prevent="onSubmit">
			<employee-absence v-model="employeePolicy.absence" :disabled="disabled" />
			<div class="row mb-5">
				<div class="col-12">
					<BaseActionButton class="btn-update" v-bind:clicked="clicked" v-bind:disabled="clicked" v-show="!disabled" type="submit" id="signupButton">
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>
		</b-form>
	</section>
</template>

<script>
import EmployeeAbsence from '../components/EmployeeAbsence.vue'

export default {
	name: 'EmployeeCondition',
	components: {
		'employee-absence': EmployeeAbsence,
	},
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	async mounted() {
		if (this.editableRouter) {
			this.disabled = false
		}
		this.$parent.$emit('updateLoader')
	},
	methods: {
		setEditable() {
			this.disabled = !this.disabled
		},
		async onSubmit() {
			this.clicked = true
			const policyId = this.employeePolicy.id
			try {
				await this.$store.dispatch('employeeVuex/updateEmployeePolicy', {
					id: policyId,
					data: this.employeePolicy,
					companyId: this.companyId,
					employeeId: this.employeeId,
				})
				this.toast('Success', 'En medarbejder nu opdateret', true)
			} catch (e) {
				this.toast('Error', 'En fejl opstod under opdatering af medarbejder' + e, false)
			}
			this.clicked = false
		},
	},
	computed: {
		editableRouter() {
			return this.$route.query.editable
		},
		groups() {
			const groups = this.$store.getters['employeeVuex/company']?.groups
			return groups
		},
		companyId() {
			return this.$route.params.companyId
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		employeePolicy() {
			var policy = this.$store.getters['employeeVuex/employeePolicy']
			return policy
		},
		employeeInfo() {
			return this.$store.getters['employeeVuex/user']
		},
		employeeId() {
			return this.$route.params.employeeId
		},
	},
}
</script>

<style></style>
